export const environment = {
  production: false,

  strapi_server: 'https://recepciones.frnautomotriz.net:1437/api',
  strapi_file_library:'https://recepciones.frnautomotriz.net:1437',
  twoFactorAuth_server:'https://recepciones.frnautomotriz.net:1438',
  report_server:'https://recepciones.frnautomotriz.net:1439',
  instance_name: "frn",
  
  s3_bucket:"https://file-storage.us-mia-1.linodeobjects.com/",
};
